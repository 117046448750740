import React, { useMemo } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useImagesContext } from '../context/ImagesContext';

type ImageProps = {
  src: string;
  alt: string;
  height?: string;
};

const Image = ({ src, alt, height }: ImageProps) => {
  const { data } = useImagesContext();
  const fluid = useMemo(
    () => data.allFile!.nodes!.find(({ relativePath }: { relativePath: string }) => src === relativePath).childImageSharp.gatsbyImageData,
    [data, src]
  );
  return <GatsbyImage style={{ width: '100%', ...(height && { height }) }} imgStyle={{ objectFit: 'contain' }} image={fluid} alt={alt} />;
};

export default Image;

