export const colors = {
  lightGrey: '#bdbdbd',

  green: '#009640',
  red: '#E30613',
  white: 'white',
  black: 'black',

  // KEEPER COLORS
  moneyGreen: '#23A06C',
  moneyGreenDark: '#22815D',
  paperGrey: '#EDECED',
  orangeGold: '#F38A34',
};

