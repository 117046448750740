import { Box } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import Header from '../components/Header';
import '../../styles.css';
import { colors } from '../styles/colors';
import AppProvider from '../context/AppContext';
import ImagesProvider from '../context/ImagesContext';
import { dimensions } from '../styles/dimensions';
import Footer from '../components/Footer';

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box sx={{ overflowX: 'hidden', position: 'relative', backgroundColor: colors.paperGrey, minHeight: '100vh' }}>
      <AppProvider>
        <ImagesProvider>
          <Header />
          <Box sx={{ marginTop: { xs: dimensions.navHeightMobile, md: dimensions.navHeightDesktop } }}>{children}</Box>
          <Footer />
        </ImagesProvider>
      </AppProvider>
    </Box>
  );
};

export default Layout;

