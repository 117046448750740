import React, { createContext, FC, PropsWithChildren, useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export type ImagesContextType = {
  data: any;
};

const ImagesContext = createContext<ImagesContextType>({ data: {} });

const ImagesProvider: FC<PropsWithChildren> = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  `);
  const context = { data };
  return <ImagesContext.Provider value={context}>{children}</ImagesContext.Provider>;
};

export const useImagesContext = () => {
  return useContext(ImagesContext);
};

export default ImagesProvider;

