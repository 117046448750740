import { Box, Container, useTheme } from '@mui/material';
import React, { useState, useEffect, FC } from 'react';
import Navigation from './Navigation';

import { ScrollDirection, useScrollDirection } from '../hooks/useScrollDirection';
import { dimensions } from '../styles/dimensions';
import Image from './Image';

const Header: FC = () => {
  const [isVisible, setIsVisible] = useState(true);
  const { scrollDirection } = useScrollDirection();
  const theme = useTheme();

  useEffect(() => {
    setIsVisible(scrollDirection === ScrollDirection.DOWN ? false : true);
  }, [scrollDirection]);

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        position: 'fixed',
        top: isVisible ? 0 : -500,
        left: 0,
        zIndex: 101,
        width: '100%',
        boxShadow: 8,
        transition: 'top 350ms',
        height: {
          xs: dimensions.navHeightMobile,
          md: dimensions.navHeightDesktop,
        },
        boxSizing: 'border-box',
      }}
    >
      <Container
        disableGutters={true}
        maxWidth={false}
        sx={{
          display: 'flex',
          justifyItems: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          component={'a'}
          href="/"
          sx={{
            height: {
              xs: `calc(${dimensions.navHeightMobile} - ${theme.spacing(0)})`,
              md: `calc(${dimensions.navHeightDesktop} - ${theme.spacing(6)})`,
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            maxWidth: { xs: '200px', md: '260px' },
          }}
        >
          <Image src={'pikalainavertailu_logo.png'} alt={'Pikalainavertailu logo'} />
        </Box>
        <Navigation />
      </Container>
    </Box>
  );
};

export default Header;

