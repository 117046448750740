import { createTheme, responsiveFontSizes } from '@mui/material/styles';
const appTheme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: ['Roboto', 'Caveat', 'sans-serif'].join(','),
      h1: {
        fontSize: '3rem',
      },
      h2: {
        fontSize: '2rem',
      },
      h3: {
        fontSize: '1.5rem',
      },
      h4: {
        fontSize: '1.3rem',
      },
      h5: {
        fontSize: '1.1rem',
      },
    },
  })
);

export default appTheme;

