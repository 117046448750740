import { Box, styled, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, { Fragment } from 'react';
import { colors } from '../styles/colors';
import { transientOptions } from '../utils/transitient-utils';
import { useMenuItems } from '../hooks/useMenuItems';
import { dimensions } from '../styles/dimensions';
import { useAppContext } from '../context/AppContext';
import { Link } from 'gatsby';

const NavItemWrapper = styled(
  Box,
  transientOptions
)(({ theme }) => ({
  cursor: 'pointer',
  userSelect: 'none',
  textAlign: 'center',
  position: 'relative',
  textTransform: 'uppercase',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: 0,
  paddingRight: 0,
  height: 'none',
  display: 'unset',
  alignItems: 'none',
  justifyContent: 'none',
  border: 'none',
  '&:hover': {
    color: 'none',
    backgroundColor: 'none',
  },
}));

const BurgerMenuIcon = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  top: dimensions.navHeightMobileRaw / 2 - 17.5,
  right: theme.spacing(2),
  zIndex: 1002,
  color: colors.moneyGreenDark,
}));

type MobileNavWrapperProps = {
  $isMenuOpen: boolean;
};

const MobileNavWrapper = styled(
  Box,
  transientOptions
)<MobileNavWrapperProps>(({ $isMenuOpen, theme }) => ({
  backgroundColor: colors.moneyGreenDark,
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  width: '100%',
  height: '100vh',
  left: $isMenuOpen ? 0 : '100%',
  top: dimensions.navHeightMobile,
  zIndex: 101,
  overflowY: 'auto',
  paddingLeft: theme.spacing(4),
  paddingTop: theme.spacing(6),
  transition: 'left 0.25s ease-in-out',
}));

const StyledTypography = styled(Typography)(({}) => ({
  color: colors.white,
  // fontWeight: 'bold',
  textTransform: 'uppercase',
  '&:hover': {
    color: colors.lightGrey,
  },
}));

type NavItemProps = {
  name: string;
  path: string;
  onClick?: () => void;
  mobile?: boolean;
};

const NavItem = ({ name, path, mobile, onClick }: NavItemProps) => (
  <NavItemWrapper>
    <Box onClick={onClick} sx={{ ...(mobile && { display: 'flex', position: 'relative' }) }}>
      <Link to={path} style={{ textDecoration: 'none' }}>
        <StyledTypography {...(mobile && { variant: 'h2' })}>{name}</StyledTypography>
      </Link>
    </Box>
  </NavItemWrapper>
);

const Navigation: React.FC = () => {
  const navItems = useMenuItems();
  const { isMenuOpen, setIsMenuOpen } = useAppContext();

  const handleMenuItemClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Fragment>
      {/* Mobile navigation BEGIN */}
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <BurgerMenuIcon onClick={handleMenuItemClick}>
          {isMenuOpen ? <CloseIcon fontSize="large" /> : <MenuIcon fontSize="large" />}
        </BurgerMenuIcon>
        <MobileNavWrapper $isMenuOpen={isMenuOpen}>
          <Box>
            {navItems.map((navItem, index) => (
              <Box pb={1}>
                <NavItem key={index} name={navItem.name} path={navItem.path} mobile={true} onClick={handleMenuItemClick} />
              </Box>
            ))}
          </Box>
        </MobileNavWrapper>
      </Box>
      {/* Mobile navigation END */}
      {/* Desktop navigation BEGIN */}
      <Box
        sx={{
          display: { xs: 'none', md: 'block' },
          backgroundColor: colors.moneyGreenDark,
          width: '100%',
          pt: 1.5,
          pb: 1.5,
        }}
      >
        <Grid2 container columnSpacing={4} justifyContent={'center'}>
          {navItems.map((navItem, index) => (
            <Grid2 key={index}>
              <NavItem name={navItem.name} path={navItem.path} />
            </Grid2>
          ))}
        </Grid2>
      </Box>
      {/* Desktop navigation END */}
    </Fragment>
  );
};

export default Navigation;

