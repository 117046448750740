import { graphql, useStaticQuery } from 'gatsby';
import { articlePath, loanCompaniesPath } from '../../config';

type MenuItem = {
  path: string;
  name: string;
  exludedFromNavigation: boolean;
};

const exludedPaths = [`${articlePath}`, `${loanCompaniesPath}`];

export const useMenuItems = (): MenuItem[] => {
  const data = useStaticQuery(graphql`
    query {
      allSitePage {
        edges {
          node {
            path
            pageContext
          }
        }
      }
    }
  `);

  const menuItems = data.allSitePage.edges
    .map((edge: any) => ({
      path: edge.node.path,
      name: edge.node.pageContext.name,
      exludedFromNavigation: edge.node.pageContext.exludedFromNavigation,
    }))
    .filter((item: MenuItem) => !item?.exludedFromNavigation && item.name);

  return menuItems;
};

