import { Box, Container, Typography } from '@mui/material';
import React, { FC } from 'react';

import Image from './Image';

const Footer: FC = () => {
  const now = new Date();

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        left: 0,
        zIndex: 101,
        width: '100%',
        boxShadow: 8,
        transition: 'top 350ms',
        boxSizing: 'border-box',
        textAlign: 'center',
        padding: 2,
      }}
    >
      <Container
        disableGutters={true}
        maxWidth="md"
        sx={{
          display: 'flex',
          justifyItems: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          pt: { xs: 1, md: 2 },
        }}
      >
        <Box sx={{ maxWidth: { xs: '150px', md: '200px' }, paddingBottom: 2 }}>
          <Image src={'pikalainavertailu_logo.png'} alt={'Pikalainavertailu logo'} />
        </Box>
        <Box pb={1}>
          <Typography fontSize={12} fontStyle={'italic'}>
            {
              'Pikalainavertailu on suomalainen pikalainojen, jousto- ja kulutusluottojen vertailupalvelu. Keräämme sivustollemme suomessa toimivien luotonantajien lainatuotteita ja esittelemme niiden kulurakennetta, korkotasoa ja voimassaolevia tarjouksia siten, että kuluttajan on helppo vertailla eri lainatuotteiden kokonaishintoja. Palvelu ei välttämättä sisällä kaikkia Suomessa saatavilla olevia lainoja, eikä näin ollen kuvasta kokonaisvaltaisesti luottomarkkinoita. Sivusto voi saada myönnetyistä lainoista tai lainahakemuksista komissioita.'
            }
          </Typography>
        </Box>
        <Box pb={1}>
          <Typography fontSize={12} fontStyle={'italic'}>
            {
              'Pyrimme jatkuvasti parantamaan sivustomme laatua ja kuuntelemme aktiivisesti sivuston käyttäjiä. Mikäli teillä on mielessänne jokin kehitysidea, voitte olla yhteydessä asiakaspalveluumme: asiakaspalvelu@pikalainavertailu.info'
            }
          </Typography>
        </Box>
        <Box pb={2}>
          <Typography>© {now.getFullYear()} Pikalainavertailu</Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;

